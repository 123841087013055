/**
 * AuthService
 * 
 * Servicio en el cual se gestionan las llamadas a el API relacionadas con la Autenticación
 * 
 * - Funciones internas
 *      - _fetch
 *      - _checkStatus
 *      - _setParams
 * 
 * - Funciones llamadas API
 *      - loginUserPassword
 * 
 */
import decode from 'jwt-decode';
import md5 from 'js-md5';

export default class AuthService {

    // Inicializamos variables
    constructor(domain) {
        this.domain = domain || /*'http://192.168.1.103/api.grupo-jcm.com'*/'https://api.grupo-jcm.com' // API server domain

        // Bindeamos las funciones
        this.loginUserPassword = this.loginUserPassword.bind(this);
    }

    /**
     *  LLAMADAS API
     **/

    /**  
     * Acceso a la App mediante login usuario:clave
     * */
    async loginUserPassword(usuario, clave) {
        clave = md5(clave);
        // Seteamos parámetros a enviar a la llamada
        let bodyParams = this._setParams({
            usuario,
            clave
        });
        // Realizamos llamada
        return this._fetch(`${this.domain}/Auth/Token`, {
                method: 'POST',
                body: bodyParams
            }).then(res => {
                this.setToken(res.token);
                return Promise.resolve(res);
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }

    /**
     * Login mediante hash/uuid
     */
    async loginHash(hash) {
        // Pareamos hash
        // hash = `1xkd6${hash}5s8fg`;
        // Seteamos parámetros a enviar a la llamada
        let bodyParams = this._setParams({
            hash
        });
        // Realizamos llamada
        return this._fetch(`${this.domain}/Auth/Token`, {
                method: 'POST',
                body: bodyParams
            }).then(res => {
                this.setToken(res.token);
                return Promise.resolve(res);
            })
            .catch(err => {
                return Promise.reject(err);
            });   
    }

    /**
     * Chequea si está logueado
     */
    checkLoggedIn(){
        return this._fetch(`${this.domain}/Usuario`, {
                method: "GET",
            }).then(res => {
                return Promise.resolve(res);
            })
            .catch(err => {
                return Promise.reject(err);
            });
    }

    /**
     * Logout
     */
    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
    }

    /**
     * Obtener profile
     */
    getProfile() {
        return decode(this.getToken());
    }

    /**
     * Obtener token de localStorage
     */
    getToken() {
        // Retrieves the user token from localStorage
        var idToken = localStorage.getItem('id_token');
        return idToken;
    }

    /**
     * Guardar token en localStorage
     */
    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }
    
    /**
     * Checkeamos si el token almacenado continua siendo válido
     */
    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // GEtting token from localstorage
        return !!token && !this._isTokenExpired(token) // handwaiving here
    }

    /**
     * Obtenemos hash del usuario
     */
    getHash() {
        const prof = this.getProfile()
        return prof.hash
    }



    /**
     *  -- LLAMADAS API
     **/

    /**
     *  FUNCIONES INTERNAS 
     **/

     /**
      * Comprobar si el token ha expirado
      * 
      * @param string token 
      */
     _isTokenExpired(token) {
         try {
             const decoded = decode(token);
             if (decoded.exp < Date.now() / 1000) { // Checking if token is expired. N
                 return true;
             } else
                 return false;
         } catch (err) {
             return false;
         }
     }

    /**
     * Llamada a la URL
     * 
     * @param string url, url a la que se va a realizar la llamada
     * @param array options, array de parámetros tanto de cabecera, método (post,get), parámetros del método, etc..
     */
    _fetch(url, options) {
        const headers = {
            'Accept': '*/*',
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        if (this.loggedIn()) {
            headers['Authorization'] = this.getToken()
            headers['Hash'] = 'x478s'+this.getHash()+'54q83'
        }

        return fetch(url, {
                headers,
                ...options
            })
            .then(this._checkStatus)
            .then(response => response.json())
    }

    /**
     * Comprobamos estado de la llamada
     * 
     * @param Response response 
     */
    _checkStatus(response) {
        // Lanza error si el estado de la respuesta no es Success
        if (response.status >= 200 && response.status < 300) { // Código de estado Success entre 200 y 300
            return response
            // error
        } else {
            var error = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    /**
     * Parsea objeto de variables a parámetros correctos para enviar por método POST,GET
     * 
     * si xx = 123 y zz = 321
     *  ----------------------------
     * | params   |  return         |
     * | {xx,zz}  |  xx=123,zz=321  |
     *  ----------------------------
     * 
     * @param Object params, objeto que contiene los parámetros
     */
    _setParams(params) {
        let bodyParams = Object.keys(params).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
        }).join('&');
        return bodyParams;
    }

    /**
     *  -- FUNCIONES INTERNAS 
     **/
}