import React, {Component} from 'react';
import './App.css';
// import "./css/general.scss";
import "./css/new.scss";

import AuthService from './utils/AuthService';
import ContenedorLogin from './components/contenedor-login';
import Loading from './components/Loading';
// Funciones
import {getBote,getConfigJuegos} from './utils/ApiClient'

// Componente Bote
function Bote(props){
	return(
		<div className={`contenedor-bote ${props.config.clase}`}>
            {/*V1:
			<div className="logo">
				<span className={`ico icon-${props.config.svg_ico}`}> </span>
				<p className="nombre-juego">{props.data.juego}</p>
			</div>
			<div className="bote">
				<div className="fecha"><p className="dia">{props.data.fecha && props.data.fecha.dia ? props.data.fecha.dia : ''}</p><p className="numero">{props.data.fecha && props.data.fecha.numero ? props.data.fecha.numero : ''}</p></div>
				<div className="importe"><span>{props.data.importe ? props.data.importe : ''}</span></div>
            </div> */}
            {/*V2:*/}
            <div className="cabecera">
                <div className="logo">
                    <span className={`ico icon-${props.config.svg_ico}`}> </span>
                    <p className="nombre-juego">{props.data.juego}</p>
                </div>
				<div className="fecha">
                    <p className="dia">{props.data.fecha && props.data.fecha.dia ? props.data.fecha.dia : ''}</p>
                    <p className="completa">{props.data.fecha && props.data.fecha.completa ? props.data.fecha.completa.replace(/-/g,'/') : ''}</p>
                </div>
            </div>
			<div className="bote">
				<span>{props.data.importe ? props.data.importe.replace('€','') : ''}</span>
            </div>
            <div className="pie">Euros</div>
		</div>
	)
}

export default class App extends Component {
  
    constructor(props) {
        super(props)
        this.state = {
            hash: null,
            update: false,
            botes: null,
            vista: null,
            config_juegos: null,
            logged: true,
            // classFondo: false
        }
        this.Auth           = new AuthService();
        this.handleLogout   = this.handleLogout.bind(this);
        this.handleLogin    = this.handleLogin.bind(this);    
        this.intervaloBotes = null;
    }

    /**
     * WillMount del componente
     * 
     * - Comprobamos si está logueado o no el dispositivo, de ser
     * así obtenemos la configuración y los datos necesarios
     * 
     */
    componentWillMount() {        
        try {
            //check si estamos logueados
            this.Auth.checkLoggedIn()
            .then(res => {
                //LOGUEADO
                if (res.status === 200) {
                    // console.log("Login Ok: ", res.menssage);
                    try {
                        const profile = this.Auth.getProfile()
                        this.setState({ hash: profile.hash, logged: true })
                        this._getBotes();
                    }
                    catch (err) {
                        console.log("error login: ", err);
                        this.handleLogout()
                    }
                //NO LOGUEADO
                } else {
                    console.log("no logueado, probando hash 1:", this.props.params.hash)
                    if (!this.props.params.hash) {
                        this.handleLogout();
                    } else {
                        //probamos login por hash
                        this.Auth.loginHash(this.props.params.hash)
                        .then(res => {
                            if(res.status === 200){
                                const profile = this.Auth.getProfile()
                                this.setState({ hash: profile.hash, logged: true })
                                this._getBotes();
                            }else{
                                console.log("error login6");
                                document.getElementById('error-msg').innerHTML = 'Error autenticando';
                                this.handleLogout();
                            }
                        })
                        .catch(err => {
                            console.log("error login1: ", err);
                            document.getElementById('error-msg').innerHTML = err;
                        })
                    }
                }
            })
            .catch(err => {
                console.log("NO logueado, probando hash 2:", this.props.params.hash)
                if (!this.props.params.hash) {
                    console.log("error login5: ", err);
                    this.handleLogout();
                } else {
                    try {
                        //probamos login por hash
                        this.Auth.loginHash(this.props.params.hash)
                        .then(res => {   
                            if(res.status === 200){
                                const profile = this.Auth.getProfile()
                                this.setState({ hash: profile.hash, logged: true })
                                this._getBotes();
                            }else{
                                console.log("error login7");
                                document.getElementById('error-msg').innerHTML = 'Error autenticando';
                                this.handleLogout();
                            }                        
                        })
                        .catch(err => {
                            console.log("error login2: ", err);
                            document.getElementById('error-msg').innerHTML = err;
                            this.handleLogout()
                        })
                    } catch (err) {
                        console.log("error login3: ", err);
                        document.getElementById('error-msg').innerHTML = err;
                        this.handleLogout()
                    }
                }
            })
        } catch (err) {
            console.log("error mounting: ", err)
            this.handleLogout()
            setTimeout(() => {
                document.getElementById('error-msg').innerHTML = "Error al acceder";
            }, 1000)
        }
    }
    componentWillUnmount() {
        clearInterval(this.intervaloBotes);
    }
    async _getBotes(){
        getConfigJuegos()
            .then(config => {
                console.log("Config: ", config);
                this.setState({
                    config_juegos: config
                });
                getBote('All')
                    .then(botes => {
                        console.log("Botes: ", botes);
                        this.setState({
                            botes: botes
                        })
                        this._doblado();
                        /*V1: this._animarBotes();*/
                    })
                    .catch(err => {
                        console.log(err)
                    });

            })
            .catch(err => {
                console.log(err)
            });
    }


    // Función para manejar el logout
    handleLogout() {
        this.Auth.logout()
        this.setState({ hash: null, logged: false  })
    }

    // Funcion para manejar el login
    handleLogin(usuario, clave) {
        //si tenemos usuario y clave hacemos el login
        if (usuario != null && clave != null) {
            this.Auth.loginUserPassword(usuario, clave)
                .then(res => {
                    const profile = this.Auth.getProfile()
                    this.setState({ hash: profile.hash, logged: true  })
                    this._getBotes();
                })
                .catch(err => {
                    console.log("error login: ", err);
                    document.getElementById('error-msg').innerHTML = err;
                })
        } else {
            return false
        }
    }

    // Manejamos click en botón de juego
    handleClick = (value) => {
        this.setState({vista:value});
    }

    _animarBotes = () => {
        if (document.getElementById('listado-botes')) {
            let totalElementos = document.getElementById('listado-botes').querySelectorAll(".contenedor-bote")
            let numBotesAnimados = totalElementos.length

            if (numBotesAnimados > 0) {
                let i = -1
                let delay = 5000
                // Intevarlo
                this.intervaloBotes = setInterval(
                    () => {      
                        i++
                        if (i === (numBotesAnimados)) {
                            i = 0
                            totalElementos[i].classList.add("animar");
                        } else {
                            totalElementos[i].classList.add("animar");
                        }
                        setTimeout(()=>{
                            totalElementos[i].classList.remove("animar");
                        },1500);
                        
                    },
                    delay
                )
            }
        }
    }
    // Función para dar estilo doblado al pie de cada bote
    _doblado = () => {
        if (document.getElementById('listado-botes')){
            let botes = document.getElementById('listado-botes').querySelectorAll(".contenedor-bote");
            botes.forEach(bote => {
                // bote.style.border = "3px solid #f0f";
            });
        }
    }

    render() {        
        return (
            <div className={`home-mainWrapper`}>
                {this.state.hash ?
                    this.state.botes && this.state.config_juegos ?                            
                            <div className="contenido-botes">
                                {/*V1: <h1>LISTADO DE BOTES SEMANALES</h1>*/}
                                {/*V2:*/}
                                <div className="titular">
                                    <h1>BOTES</h1>
                                    <h2>UN ÚNICO ACERTANTE PODRÍA GANAR</h2>
                                </div>
                                {/*-- V2*/}
                                <div className="contenedor-botes" id="listado-botes">
                                    {this.state.botes.map((item,i) =>{                                    
                                        return item.importe && item.fecha ? <Bote key={i} data={item} config={this.state.config_juegos.config[item.key]}/>:''})}
                                        <Bote  data={this.state.botes[1]} config={this.state.config_juegos.config[1]}/>
                                        <Bote  data={this.state.botes[2]} config={this.state.config_juegos.config[2]}/>
                                </div>                            
                            </div>
                        : <Loading message="Cargando datos..." />
                : this.state.logged ? 
                    <Loading message="Conectando..." />
                    :<ContenedorLogin params={this.props.params} login={this.handleLogin} />
                }
            </div>
        );
    }
}
