import AuthService from './AuthService';


export const getBote = async (juego) => {
    const auth = new AuthService()
    const _url = `${auth.domain}/Botes/${juego}`
    try {
        const cabeceras = new Headers({
            "Authorization": auth.getToken(),
            "Hash": `x4sx2${auth.getHash()}d144f`
        });
        const resp = await fetch(_url, {
            method: "GET",
            headers: cabeceras
        })
        let post = await resp.json()
        if (post.status === 200 && !post.error)
            return post.botes;
        else
            console.error(`Error ${post.status}: ${post.message}`)
    } catch (err) {
        console.error(`Error: ${err}`)
    }
}


export const getConfigJuegos = async () => {
    const auth = new AuthService()
    const _url = `${auth.domain}/Config`
    try {
        const cabeceras = new Headers({
            "Authorization": auth.getToken(),
            "Hash": `a84wd${auth.getHash()}xx74c`
        });
        const resp = await fetch(_url, {
            method: "GET",
            headers: cabeceras
        })
        const post = await resp.json()
        if (post.status === 200 && !post.error) {
            return {
                "status": post.status,
                "config": post.config
            }
        } else {
            console.error(`Error2: ${post.status}: ${post.message}`)
            return {
                "status": post.status,
                "message": post.message
            }
        }
    } catch (err) {
        console.error(`Error1: ${err}`)
    }
}

export const getConfigUsuario = async () => {
    const auth = new AuthService()
    const _url = `${auth.domain}/Usuario/Config`
    try {
        const cabeceras = new Headers({
            "Authorization": auth.getToken(),
            "Hash": `us23j${auth.getHash()}m1op8`
        });
        const resp = await fetch(_url, {
            method: "GET",
            headers: cabeceras
        })
        const post = await resp.json()
        if (post.status === 200 && !post.error) {
            //const config = setConfigUsuario(post.config)
            //console.log("[temp api-client] Configuración usuario:",config)
            return {
                "status": post.status,
                "config": post.config
            }
        } else {
            console.error(`Error ${post.status}: ${post.message}`)
            return {
                "status": post.status,
                "message": post.message
            }
        }
    } catch (err) {
        console.error(`Error: ${err}`)
        return {
            "status": 500,
            "message": err.message
        }
    }
}

/*
const setConfigUsuario = (config) => {
    return config_usuario
}*/
